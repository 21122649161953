<template>
  <v-container fluid>
    <v-row style="margin: 0px">
      <v-flex xs12 sm12 md12>
        <v-card flat class="transparent">
          <v-card-title>Filter </v-card-title>
          <v-row class="pl-5 pr-5">
            <v-col cols="12" sm="2" md="2">
              <v-autocomplete
                v-model="selectedList"
                :items="lists"
                item-text="name"
                item-value="id"
                label="Lists"
                autocomplete="false"
                class="my-2"
                hide-details
                solo
                flat
                outlined
                background-color="inverse"
                multiple
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index === 0" label small>
                    <span>{{ item.name }}</span>
                  </v-chip>
                  <span v-if="index === 1" class="grey--text caption">
                    (+{{ selectedList.length - 1 }} others)
                  </span>
                </template>
              </v-autocomplete>
            </v-col>

            <v-col cols="12" sm="2" md="2">
              <v-autocomplete
                v-model="selectedUser"
                :items="allUsers"
                item-text="name"
                item-value="id"
                label="Members"
                autocomplete="false"
                class="my-2"
                hide-details
                solo
                flat
                outlined
                background-color="inverse"
                multiple
                style="min-height: 56px!important;"
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index === 0" label small>
                    <span>{{ item.name }}</span>
                  </v-chip>
                  <span v-if="index === 1" class="grey--text caption">
                    (+{{ selectedUser.length - 1 }} others)
                  </span>
                </template>
              </v-autocomplete>
            </v-col>

            <v-col cols="12" sm="2" md="2">
              <v-select
                v-model="selectedDispos"
                :items="calcDispo"
                item-text="title"
                item-value="title"
                label="Dispo"
                class="my-2"
                hide-details
                solo
                flat
                outlined
                background-color="inverse"
                multiple
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index === 0" label small>
                    <span>{{ item.title }}</span>
                  </v-chip>
                  <span v-if="index === 1" class="grey--text caption">
                    (+{{ selectedDispos.length - 1 }} others)
                  </span>
                </template>
              </v-select>
            </v-col>

            <v-col cols="12" sm="3" md="3">
              <v-dialog
                ref="dialog"
                v-model="modal"
                :return-value.sync="dates"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="dateRange1"
                    label="Date Range"
                    prepend-inner-icon="event"
                    class="my-2"
                    hide-details
                    solo
                    flat
                    outlined
                    background-color="inverse"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="dates"
                  scrollable
                  color="primarygrad"
                  range
                >
                  <v-spacer></v-spacer>
                  <v-btn text @click="modal = false">Cancel</v-btn>
                  <v-btn
                    text
                    color="primarygrad"
                    @click="$refs.dialog.save(dates)"
                    >OK</v-btn
                  >
                </v-date-picker>
              </v-dialog>
            </v-col>

            <v-col xs12 sm2 md2>
              <v-btn
                color="primarygrad white--text"
                style="margin-top: 16px"
                block
                @click="load"
              >
                Generate
              </v-btn>
            </v-col>
          </v-row>

          <v-flex v-if="preloader" xs12 sm12 md12 style="padding: 5px">
            <v-card class="fill-height secondarygrad rounded-lg">
              <v-card-text>
                <div
                  style="text-align: center; padding: 50px; margin-top: 80px"
                >
                  <v-progress-circular
                    :width="3"
                    color="primary"
                    indeterminate
                  ></v-progress-circular>
                </div>
              </v-card-text>
            </v-card>
          </v-flex>

          <v-flex
            v-if="graphData.length > 0 && !preloader"
            xs12
            sm12
            md12
            style="padding: 5px"
          >
            <v-card
              class="fill-height rounded-lg"
              style="background: transparent"
            >
              <v-card-text>
                <v-row>
                  <v-col>
                    <v-card class="pa-2">
                      <div style="padding-bottom: 2px;" align="center">
                        <h3 style="padding-bottom: 6px;">
                          Disposition Summary
                        </h3>
                      </div>
                      <v-row
                        class="fill-height pt-6 pb-4"
                        align="center"
                        justify="center"
                      >
                        <doughnut-chart
                          class="text-align-center justify-content-center"
                          style="
                                              width: 100%;
                                              max-width: 300px;
                                              margin: 0 auto;
                                            "
                          :chart-data="datacollection"
                          :options="{
                            legend: {
                              position: 'bottom',
                              display: labelShown,
                            },
                          }"
                        ></doughnut-chart>
                      </v-row>
                    </v-card>
                  </v-col>

                  <v-col>
                    <v-card class="pa-2">
                      <div style="padding-bottom: 2px;" align="center">
                        <h3 style="padding-bottom: 6px;">Call Summary</h3>
                      </div>
                      <v-row
                        class="fill-height pt-6 pb-4"
                        align="center"
                        justify="center"
                      >
                        <bar-chart
                          class="text-align-center justify-content-center"
                          style="
                                              width: 100%;
                                              max-width: 300px;
                                              margin: 0 auto;
                                            "
                          :chart-data="barChartData"
                          :options="{
                            legend: { display: false },
                            scales: {
                              yAxes: [
                                {
                                  gridLines: { display: false },
                                  ticks: { beginAtZero: true },
                                },
                              ],
                              xAxes: [
                                {
                                  gridLines: { display: false },
                                },
                              ],
                            },
                          }"
                        ></bar-chart>
                      </v-row>
                    </v-card>
                  </v-col>

                  <v-col>
                    <v-card class="pa-2">
                      <div style="padding-bottom: 2px;" align="center">
                        <h3 style="padding-bottom: 6px;">Contact Ratio</h3>
                      </div>
                      <v-row
                        class="fill-height pt-6 pb-4"
                        align="center"
                        justify="center"
                      >
                        <pie-chart
                          class="text-align-center justify-content-center"
                          style="
                                              width: 100%;
                                              max-width: 300px;
                                              margin: 0 auto;
                                            "
                          :chart-data="pieChartData"
                        ></pie-chart>
                      </v-row>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-card>
      </v-flex>

      <v-flex xs12 sm12 md12 class="pl-5 pr-5">
        <v-card class="secondarygrad" style="margin: 10px 0px">
          <v-card-title>
            <v-row wrap style="margin: 0px" @keyup.enter="query">
              <v-flex xs12 sm4 md5 class="px-1">
                <v-text-field
                  v-model="search"
                  prepend-inner-icon="search"
                  clearable
                  solo-inverted
                  flat
                  dense
                  single-line
                  @click:clear="searchReset()"
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm2 md2 class="px-1">
                <v-autocomplete
                  v-model="searchField"
                  :items="fieldsList"
                  item-value="value"
                  item-text="name"
                  autocomplete="false"
                  hide-details="true"
                  label="Fields"
                  solo-inverted
                  flat
                  dense
                ></v-autocomplete>
              </v-flex>

              <v-flex xs6 sm2 md2 class="px-1 mt-3 mt-md-0">
                <v-btn
                  color="primarygrad white--text"
                  block
                  elevation="3"
                  @click="query"
                >
                  <v-icon left>search</v-icon>
                  Search
                </v-btn>
              </v-flex>

              <v-flex
                xs6
                sm2
                md2
                class="mt-3 mt-md-0"
                style="text-align: center;"
              >
                <v-btn
                  class="secondary inverted--text"
                  block
                  @click="exportReport"
                >
                  <v-icon size="18" style="margin-right: 3px">save_alt</v-icon
                  >Export
                </v-btn>
              </v-flex>
              <v-flex
                xs12
                sm2
                md1
                class="px-1 mt-3 mt-md-0"
                style="text-align: center;"
              >
                <v-menu
                  transition="slide-y-transition"
                  fixed
                  bottom
                  :close-on-content-click="false"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip
                      v-bind="attrs"
                      label
                      style="height:36px;width:80px;justify-content: center;"
                      v-on="on"
                    >
                      <v-icon left color="primary">view_module</v-icon>

                      <v-icon color="primary">mdi-chevron-down</v-icon>
                    </v-chip>
                  </template>
                  <v-list style="max-height: 300px; overflow-y:scroll">
                    <v-list-item-group>
                      <v-list-item v-for="item in allHeaders" :key="item.value">
                        <v-list-item-content>
                          <v-list-item-title
                            style="font-weight: 500; font-size: 15px"
                            v-text="item.text"
                          ></v-list-item-title>
                        </v-list-item-content>

                        <v-list-item-action>
                          <v-checkbox
                            v-model="reportHeaders"
                            ripple
                            :value="item"
                            :disabled="
                              item.value == 'phone' ||
                                item.value == 'name' ||
                                item.value == 'email'
                            "
                            style="margin-top: -3px"
                            color="primary"
                          ></v-checkbox>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-menu>
              </v-flex>
            </v-row>
          </v-card-title>

          <v-data-table
            :headers="reportHeaders"
            :items="contactDetails"
            :items-per-page="records"
            hide-default-footer
            :loading="loading"
            :loading-text="loadingText"
          >
            <!-- <template v-slot:headers="{ reportHeaders }">
              <v-icon v-if="reportHeaders.icon" left>{{
                reportHeaders.icon
              }}</v-icon>
              {{ reportHeaders.text }}
            </template> -->

            <!-- <template v-slot:header.Duration="{ header }">
              <v-icon small>plus-circle-outline</v-icon>{{ header.text }}
            </template> -->

            <template v-slot:header.Duration="{ header }">
              <span style="display: inline-flex">
                {{ header.text }}

                <v-tooltip top max-width="300">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon small v-bind="attrs" v-on="on">info</v-icon>
                  </template>
                  <span>Total amount of Talk Time for every call</span>
                </v-tooltip>
              </span>
            </template>

            <template v-slot:header.TimeSpent="{ header }">
              <span style="display: inline-flex">
                {{ header.text }}

                <v-tooltip top max-width="300">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon small v-bind="attrs" v-on="on">info</v-icon>
                  </template>
                  <span
                    >Amount of Time spent on every contact, includes : ringing
                    time, disposition set time etc</span
                  >
                </v-tooltip>
              </span>
            </template>

            <template v-slot:[`item.dispo`]="{ item }">
              <div>
                <h5
                  v-if="item.dispo == 'CALLBACK'"
                  style="font-size: 14px; font-weight: 400"
                >
                  {{ item.dispo }} ({{
                    $moment(item.callbackOn).format("lll")
                  }})
                </h5>
                <h5 v-else style="font-size: 14px; font-weight: 400">
                  {{ item.dispo }}
                </h5>
              </div>
            </template>

            <template v-slot:[`item.Duration`]="{ item }">
              <span v-if="item.type == 'call'">
                <div v-if="parseInt(item.Duration) > 0">
                  <span style="padding-left: 6px;">
                    <v-chip small class="pointer" color="#2ecc71" label>
                      {{ item.Duration }}
                    </v-chip></span
                  >
                </div>
                <div v-else>
                  <span style="padding-left: 6px;">
                    <v-chip small class="pointer" color="#FF6384" label>
                      {{ item.Duration }}
                    </v-chip>
                  </span>
                </div>
              </span>
            </template>

            <template v-slot:[`item.phone`]="{ item }">
              <span v-if="item.type == 'call'">
                <div
                  v-if="item.direction == 'incoming'"
                  style="display: inline-flex"
                >
                  {{ item.phone }}
                  <v-icon style="padding-left: 6px;">mdi-phone-incoming</v-icon>
                </div>

                <div v-else style="display: inline-flex">
                  {{ item.phone }}
                  <v-icon style="padding-left: 6px;">mdi-phone-outgoing</v-icon>
                </div>
              </span>

              <span v-else>
                <div v-if="item.type == 'sms'" style="display: inline-flex">
                  {{ item.phone }}
                  <v-icon style="padding-left: 6px;">sms</v-icon>
                </div>

                <div v-if="item.type == 'email'" style="display: inline-flex">
                  {{ item.phone }}
                  <v-icon style="padding-left: 6px;">mdi-email</v-icon>
                </div>

                <div v-if="item.type == 'link'" style="display: inline-flex">
                  {{ item.phone }}
                  <v-icon style="padding-left: 6px;">mdi-link</v-icon>
                </div>

                <div v-if="item.type == 'app'" style="display: inline-flex">
                  {{ item.phone }}
                  <v-icon style="padding-left: 6px;">mdi-apps</v-icon>
                </div>
              </span>
            </template>

            <template v-slot:[`item.recording.name`]="{ item }">
              <div v-if="item.defaultDialer == 'Exotel'">
                <!-- <v-btn
                  text
                  fab
                  small
                  :href="item.serverResponse.RecordingUrl"
                  target="_blank"
                >
                  <v-icon style="opacity: 0.6" small>play_arrow</v-icon>
                </v-btn> -->
                <v-btn text fab small @click="openDialog(item, 'exotel')">
                  <v-icon style="opacity: 0.6" small>play_arrow</v-icon>
                </v-btn>
              </div>
              <div
                v-else-if="
                  item.defaultDialer == 'Telecmi' ||
                    item.defaultDialer == 'Telecmi-Linphone'
                "
              >
                <v-btn
                  text
                  fab
                  small
                  @click="openDialogRecordPopup(item, 'telecmi')"
                >
                  <v-icon style="opacity: 0.6" small>play_arrow</v-icon>
                </v-btn>
              </div>
              <div v-else-if="item.recording && item.recording.success">
                <v-btn
                  text
                  fab
                  small
                  @click="openDialogRecordPopup(item, 'phone')"
                >
                  <v-icon style="opacity: 0.6" small>play_arrow</v-icon>
                </v-btn>
              </div>
              <div v-else>
                Missing
              </div>
            </template>
          </v-data-table>

          <v-divider style="margin-bottom: 10px"></v-divider>
          <v-card-text style="text-align: center !important">
            <div class="v-data-footer">
              <div class="v-data-footer__select">
                Rows per page:
                <div
                  class="v-input v-input--hide-details v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted v-select"
                >
                  <v-select
                    v-model="records"
                    :items="recordsPerPage"
                    style="margin: 5px"
                    @change="pagination"
                  ></v-select>
                </div>
              </div>
              <div class="v-data-footer__icons-before">
                <v-btn
                  :disabled="page == 1 ? true : false"
                  color="primary"
                  fab
                  small
                  text
                  @click="prevSet"
                >
                  <v-icon :size="24">arrow_left</v-icon>
                </v-btn>
              </div>
              <div class="v-data-footer__pagination">
                {{ `${page}/${pageCount} of ${totalRecords}` }}
              </div>
              <div class="v-data-footer__icons-after">
                <v-btn
                  :disabled="page >= pageCount ? true : false"
                  color="primary"
                  fab
                  small
                  text
                  @click="nextSet"
                >
                  <v-icon :size="24">arrow_right</v-icon>
                </v-btn>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-row>

    <!-- Loader -->
    <v-dialog v-model="loader" persistent width="300">
      <v-card color="primary white--text" dark>
        <v-card-text style="padding: 18px 25px">
          <h3 style="margin-bottom: 5px; font-weight: 400; font-size: 16px">
            Please Wait...
          </h3>
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="loader1" persistent width="300">
      <v-card color="primary white--text" dark>
        <v-card-text style="padding: 18px 25px">
          <h3 style="margin-bottom: 5px; font-weight: 400; font-size: 16px">
            Exporting: {{ displayExportProgress }}
          </h3>
          <v-progress-linear
            :value="exportProgress"
            color="white"
            max="130"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogReportsExports" width="900">
      <v-card>
        <v-card-title class="primarygrad">
          Export Report
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogReportsExports = false">
            <v-icon>mdi-close-circle</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="mt-3">
          <v-row class="pa-0 ma-0">
            <v-col xs12 sm12 md12 style="text-align: center; padding: 5px">
              <v-dialog
                ref="dialog"
                v-model="modal"
                :return-value.sync="dates"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="dateRange"
                    label="Date Range"
                    prepend-inner-icon="event"
                    class="my-2"
                    hide-details="true"
                    solo
                    flat
                    outlined
                    background-color="inverse"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="dates"
                  scrollable
                  color="primarygrad"
                  range
                >
                  <v-spacer></v-spacer>
                  <v-btn text @click="modal = false">Cancel</v-btn>
                  <v-btn
                    text
                    color="primarygrad"
                    @click="$refs.dialog.save(dates)"
                    >OK</v-btn
                  >
                </v-date-picker>
              </v-dialog>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text color="red" @click="dialogReportsExports = false">
            Cancel
          </v-btn>
          <v-btn depressed color="primarygrad" @click="saveExportReports">
            Export
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" multi-line :color="color">
      <h3 style="font-weight: 400; font-size: 13px; text-transform: uppercase">
        {{ snackbarText }}
      </h3>

      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <record-popup ref="recordPopup"></record-popup>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
const _ = require("lodash");
import Papa from "papaparse";
import Blob from "blob";
import fb from "../js/firebase";
import DoughnutChart from "../js/doughnut.js";
import BarChart from "../js/bar.js";
import PieChart from "../js/pie.js";
import moment from "moment-timezone";
import * as XLSX from "xlsx/xlsx.mjs";
import recordPopup from "../components/recordPopup.vue";

export default {
  components: {
    DoughnutChart,
    BarChart,
    PieChart,
    recordPopup,
  },
  data() {
    return {
      exportProgress: 0,
      dialogReportsExports: false,
      telecmiIntegration: false,
      snackbar: false,
      snackbarText: "",
      color: "green",
      defaultDialer: "",
      audioDialog: false,
      selectedAudio: null,
      selectedLog: null,
      isPlaying: false,
      playbackTime: 0,
      // teams: [],
      labelShown: true,
      totalCallCount: 0,
      totalCallDuration: "",
      barChartData: {},
      pieChartData: {},
      statistics: [],
      datacollection: {},
      // pagination structure
      loader: false,
      recordsPerPage: [5, 15, 30, 60, 120],
      page: 1,
      pageCount: 0,
      totalRecords: 0,
      records: 5,
      selectedUser: [],
      selectedList: [],
      lists: [],
      templates: [],
      preloader: false,
      search: "",
      contactDetails: [],
      loadChart: false,
      allUsers: [],
      allHeaders: [
        {
          text: "Name",
          align: "left",
          sortable: false,
          value: "name",
        },
        { text: "Phone", align: "left", sortable: false, value: "phone" },
        {
          text: "Type",
          align: "left",
          sortable: false,
          value: "type",
        },
        {
          text: "Date",
          align: "left",
          sortable: false,
          value: "date",
        },
        {
          text: "Time",
          align: "left",
          sortable: false,
          value: "time",
        },
        {
          text: "Duration",
          align: "left",
          sortable: false,
          value: "Duration",
        },
        {
          text: "Time Spent",
          align: "left",
          sortable: false,
          value: "TimeSpent",
        },
        {
          text: "Dialer",
          align: "left",
          sortable: false,
          value: "defaultDialer",
        },
        {
          text: "Called/Received By",
          align: "left",
          sortable: false,
          value: "accounts.name",
        },
        {
          text: "Disposition",
          align: "left",
          sortable: false,
          value: "dispo",
        },
        {
          text: "Remarks",
          align: "left",
          sortable: false,
          value: "remarks",
        },
        {
          text: "Notes",
          align: "left",
          sortable: false,
          value: "note",
        },
        {
          text: "List",
          align: "left",
          sortable: false,
          value: "list.name",
        },
        {
          text: "Team",
          align: "left",
          sortable: false,
          value: "list.teams.name",
        },
        {
          text: "Template",
          align: "left",
          sortable: false,
          value: "template.title",
        },
        {
          text: "Recording",
          align: "left",
          sortable: false,
          value: "recording.name",
        },
        {
          text: "Call Status",
          align: "left",
          sortable: true,
          value: "callStatus",
        },
      ],
      modal: false,
      dates: [],
      dispos: [],
      selectedDispos: [],
      graphData: [],
      loading: false,
      loadingText: "",
      searchField: "",
      fieldsList: [
        { name: "Name", value: "name" },
        { name: "Phone", value: "phone" },
        { name: "Remarks", value: "remarks" },
        { name: "Note", value: "note" },
      ],
    };
  },
  computed: {
    ...mapGetters(["ENDPOINT", "user", "fieldList", "teams", "companyDetails"]),
    reportHeaders: {
      get: function() {
        return this.$store.getters.reportHeaders;
      },
      set: function(newValue) {
        this.$store.commit("setReportHeaders", newValue);
      },
    },
    dateRange() {
      return this.dates.join(" - ");
    },
    dateRange1() {
      return this.dates.join(" - ");
    },
    dispoList() {
      return this.$store.getters.dispoList;
    },
    noReports() {
      return require("../assets/noReports.png");
    },
    selectedTeam: {
      get(v) {
        return this.$store.getters.selectedTeam;
      },
      set(v) {
        this.$store.commit("setSelectedTeam", v);
      },
    },
    calcDispo() {
      return _.without(
        _.map(this.dispos, (d) => {
          if (
            d.title == "SMS" ||
            d.title == "LINK" ||
            d.title == "EMAIL" ||
            d.title == "APP" ||
            d.title == "NEW"
          ) {
            //do not pass these values
          } else {
            return d;
          }
        }),
        undefined
      );
    },
    displayExportProgress() {
      if (this.exportProgress <= 100) {
        return `Batch ${this.currentBatch}/${this.totalBatches} ( ${this.exportProgress}% )`;
      } else {
        return `Merging files: ${this.exportProgress - 100}%`;
      }
    },
    paginate() {
      return this.$store.getters.paginate;
    },
  },
  watch: {
    async selectedTeam() {
      try {
        await this.refresh(); // get accounts and lists and dispos
        await this.load(); // get the logs and load charts
      } catch (e) {
        console.log(e);
      }
    },
  },
  async mounted() {
    // Graphs
    // this.fillBarData();
    // this.fillData();
  },
  async created() {
    try {
      // Paginate
      var routeName = this.$router.currentRoute.name
        .toString()
        .trim()
        .replace(/ /g, "")
        .toLowerCase();
      if (this.paginate[routeName]) {
        this.page = this.paginate[routeName].page;
        (this.pageCount = this.paginate[routeName].pageCount),
          (this.totalRecords = this.paginate[routeName].totalRecords),
          (this.records = this.paginate[routeName].records);
      }

      await this.refresh(); // get accounts and lists and dispos
      await this.load(); // get the logs and load charts
      this.getTelecmiDetails();
    } catch (e) {
      console.log(e);
    }
  },
  methods: {
    async getTelecmiDetails() {
      var resp = await this.$http.get(
        `${this.ENDPOINT}/integrations?filter[where][companyId]=${this.companyDetails.id}&filter[where][type]=telecmi`
      );

      if (resp.body.length > 0) this.telecmiIntegration = resp.body[0];
    },
    openDialog(item, dialingOpt) {
      if (dialingOpt == "exotel") {
        (this.selectedAudio = null), (this.selectedLog = null);
        if (typeof item.serverResponse.RecordingUrl != "undefined") {
          var audioExotel = new Audio(`${item.serverResponse.RecordingUrl}`);
          this.selectedAudio = audioExotel;

          console.log("item", item);

          this.selectedLog = item;
          this.audioDialog = true;
        }
      } else {
        (this.selectedAudio = null), (this.selectedLog = null);
        if (typeof item.recording != "undefined") {
          var audioPhone = new Audio(
            `${this.$store.state.ENDPOINT}/storages/get/${item.recording.name}?access_token=${this.$store.getters.user.token}`
          );
          this.selectedAudio = audioPhone;
          this.selectedLog = item;
          this.audioDialog = true;
        }
      }
    },

    closeDialog() {
      this.selectedAudio.pause();
      // reset item var
      (this.isPlaying = false),
        (this.playbackTime = 0),
        (this.selectedAudio = null),
        (this.selectedLog = null),
        (this.audioDialog = false);
    },

    openDialogRecordPopup(item, dialingOpt) {
      if (dialingOpt == "exotel") {
        (this.selectedAudio = null), (this.selectedLog = null);
        if (typeof item.serverResponse.RecordingUrl != "undefined") {
          var audioExotel = new Audio(`${item.serverResponse.RecordingUrl}`);
          this.selectedAudio = audioExotel;

          this.selectedLog = item;
          this.audioDialog = true;
        }
      } else {
        console.log("this.$refs", this.$refs);

        this.$refs.recordPopup.openDialog(item, dialingOpt);
      }
    },

    closeDialogRecordPopup() {
      this.$refs.recordPopup.closeDialog(false);
    },

    download(src, dialingOpt) {
      if (src != "") {
        if (dialingOpt == "exotel") {
          var a = document.createElement("a");

          a.href = src;
          a.setAttribute("download", src.toString());
          a.target = "_blank";
          a.click();
        } else {
          var au = document.createElement("a");

          au.href = `${this.$store.state.ENDPOINT}/storages/get/${src}?access_token=${this.$store.getters.user.token}`;
          au.setAttribute("download", src.toString());
          au.target = "_blank";
          au.click();
        }
      }
    },
    audio(what) {
      const self = this;
      var audio = self.selectedAudio;
      if (typeof audio != "undefined") {
        switch (what) {
          case "start":
            self.isPlaying = true;
            audio.play();

            break;

          case "pause":
            // access audio from ongoing selected track
            audio.pause();
            // reset item var
            self.isPlaying = false;
            // self.playbackTime = 0;
            break;
        }

        audio.ontimeupdate = function(e) {
          self.playbackTime = (audio.currentTime / audio.duration) * 100;
          if (self.playbackTime >= 100 || isNaN(self.playbackTime)) {
            self.isPlaying = false;
            self.playbackTime = 0;
          }
        };
      }
    },

    /** SEARCH */
    async query() {
      try {
        if (this.search != "" && this.searchField != "") {
          this.loading = true;
          this.loadingText = "Loading... Please wait";
          this.page = 1;
        }
        this.fetchCount();
        this.contactDetails = await this.fetchLogs(
          this.records,
          this.records * this.page - this.records
        );
      } catch (e) {
        // error
      }
    },
    searchReset() {
      this.searchField = "name";
      this.search = "";
      this.query();
    },

    /** PAGINATION */
    nextSet() {
      this.page++;
      this.pagination();
    },
    prevSet() {
      this.page--;
      this.pagination();
    },
    async pagination() {
      this.pageCount = Math.ceil(this.totalRecords / this.records);
      if (this.page > this.pageCount) {
        this.page = 1; //set default
      }

      this.contactDetails = await this.fetchLogs(
        this.records,
        this.records * this.page - this.records
      );

      // Save default pagination of seperate pages
      this.paginate[
        this.$router.currentRoute.name
          .toString()
          .trim()
          .replace(/ /g, "")
          .toLowerCase()
      ] = {
        page: this.page,
        pageCount: this.pageCount,
        records: this.records,
        totalRecords: this.totalRecords,
      };
      this.$store.commit("setPagination", this.paginate);
      return;
    },

    /** INIT CODE */
    // Load the details

    async load() {
      this.page = 1;
      this.contactDetails = [];
      this.statistics = [];
      this.graphData = [];

      if (this.page > this.pageCount) {
        this.page = 1;
      }
      // date range limit
      var start = moment(this.dates[0]);
      var end = this.dates[1] == undefined ? start : moment(this.dates[1]);
      if (start > end) {
        var temp = start;
        start = end;
        end = temp;
      }
      var diff = end.diff(start, "days") + 1;

      // if range is greater than 30 days
      if (diff > 31) {
        this.loading = false;
        this.loadingText = "";
        this.snackbar = true;
        this.snackbarText = "Please select a range of 30 days";
        this.color = "#cc9900";
        return;
      }

      // // if select more than 5 lists
      if (this.selectedList.length > 5) {
        this.loading = false;
        this.loadingText = "";
        this.snackbar = true;
        this.snackbarText =
          " you can only select a maximum of five lists at a time. Please deselect some lists before continuing.";
        this.color = "#cc9900";
        return;
      }

      this.fetchGraphReports(); // graphical report
      this.fetchCount(); // Total log count
      this.contactDetails = await this.fetchLogs(
        this.records,
        this.records * this.page - this.records
      ); // logs by limit and skip

      // Set field data
      if (this.fieldsList.length > 0 && this.searchField == "") {
        this.searchField = this.fieldsList[0].value;
      }
    },

    // load info for filter
    async refresh() {
      try {
        await this.fetchLists();
        await this.fetchAccounts();
        await this.fetchDispos();
        await this.fetchTemplates();
      } catch (e) {
        // error
      }
    },

    /** DELETE APIIS */
    deleteItem(item) {
      const self = this;
      self.$http.delete(self.ENDPOINT + "/logs/" + item.id).then(function() {
        self.fetchLogs();
      });
    },

    /** GET ARRAY OF list, user ids */
    getListIds() {
      var lists = [];
      if (_.includes(this.selectedList, "allLists")) {
        // lists = [];
        lists = _.map(this.lists, "id");
      } else {
        lists = this.selectedList;
      }
      lists = _.without(lists, "allLists");
      return lists;
    },
    getTemplateIds() {
      var templates = [];
      templates = _.map(this.templates, "id");
      return templates;
    },
    getUserIds() {
      var users = [];

      if (
        _.includes(this.selectedUser, "allMembers") &&
        this.$store.getters.user.role.toLowerCase() == "manager"
      ) {
        users = [];
      } else if (
        _.includes(this.selectedUser, "allMembers") &&
        this.$store.getters.user.role.toLowerCase() == "teammanager"
      ) {
        users = _.map(this.allUsers, "id");
      } else {
        users = this.selectedUser;
      }
      users = _.without(users, "allMembers");
      return users;
    },

    allListReports() {
      this.dialogReportsExports = true;
    },

    async saveExportReports() {
      var self = this;

      // date range limit
      var start = moment(this.dates[0]);
      var end = this.dates[1] == undefined ? start : moment(this.dates[1]);
      if (start > end) {
        var temp = start;
        start = end;
        end = temp;
      }
      var diff = end.diff(start, "days") + 1;

      // if range is greater than 30 days
      if (diff > 31) {
        this.loading = false;
        this.loadingText = "";
        this.snackbar = true;
        this.snackbarText = "Please select a range of 30 days";
        this.color = "#cc9900";
        return;
      }

      if (this.dates.length < 1) {
        this.dates = [
          moment()
            .subtract(7, "days")
            .format("YYYY-MM-DD"),
          moment().format("YYYY-MM-DD"),
        ];
      }

      var lists = _.map(this.lists, "id");

      lists = _.without(lists, "allLists");

      const searchParamsForCount = {
        search: "",
        field: "",
        range: this.dates,
        lists: lists,
        users: [],
        dispos: [],
        count: true,
      };

      const responseCount = await this.$http.post(
        this.ENDPOINT + "/logs/byFilter",
        searchParamsForCount
      );

      console.log("response of count", responseCount);

      var dataResultFinal = [];

      await Promise.all(
        lists.map(async (c) => {
          var searchParams = {
            search: "",
            field: "",
            range: this.dates,
            lists: [c],
            users: [],
            dispos: [],
            count: false,
          };

          var response = await this.$http.post(
            this.ENDPOINT + "/logs/byFilter",
            searchParams
          );

          console.log("response body", response.body.data);

          if (response.body.data.length > 0) {
            dataResultFinal.push(...response.body.data);
          }
        })
      );

      console.log("data", dataResultFinal);

      // const searchParams = {
      //   search: "",
      //   field: "",
      //   range: this.dates,
      //   lists: lists,
      //   users: [],
      //   dispos: [],
      //   limit: 5,
      //   skip: 0,
      //   count: false,
      // };
      // const data = [];
      // const result = await this.fetchData(searchParams, data, responseCount);
      // console.log(result);

      // if (typeof result != "undefined") {
      //   data.push(result);
      // }

      var dataResult = _.map(dataResultFinal, (r) => {
        // accounts
        var account = _.find(this.allUsers, (user) => {
          return r.accountsId == user.id;
        });
        if (account) {
          r.accounts = account;
        }

        //lists
        var list = _.find(this.lists, (list) => {
          return r.listId == list.id;
        });
        if (list) {
          r.list = list;
        }

        //templates
        var template = _.find(this.templates, (template) => {
          return r.templateId == template.id;
        });
        if (template) {
          r.template = template;
        }

        return r;
      });

      console.log("dataResult", dataResult);

      var exportData = [];

      exportData = _.orderBy(dataResult, "time", "desc");

      console.log("exportData", exportData);

      if (exportData.length < 1) {
        this.loader = false;
        this.$swal({ tyep: "warning", text: "No records to export" });
        return;
      }

      exportData = _.map(exportData, (d) => {
        var y = {
          Phone: d.phone.toString(),
        };

        if (typeof d.name != "undefined") {
          y.Name = d.name;
        } else {
          y.Name = "";
        }

        if (typeof d.companyName != "undefined") {
          y.Company = d.companyName;
        } else {
          y.Company = "";
        }

        if (d.calledOn) {
          y.Date = this.$moment(d.calledOn).format("YYYY-MM-DD");
          y.Time = this.$moment(d.calledOn).format("hh:mm a");
        } else {
          y.Date = "";
          y.Time = "";
        }

        if (d.leadScore) {
          y.Leadscore = d.leadScore;
        } else {
          y.Leadscore = "";
        }

        if (d.remarks) {
          y.Remarks = d.remarks;
        } else {
          y.Remarks = "";
        }

        if (d.extra) {
          y.Extra = d.extra;
        } else {
          y.Extra = "";
        }

        if (d.accounts && d.accounts.name) {
          y.CalledBy = d.accounts.name;
        } else {
          y.CalledBy = "";
        }

        if (d.direction) {
          y.Direction = d.direction;
        } else {
          y.Direction = "outgoing";
        }

        if (typeof d.list == "undefined" || d.list.name == "") {
          y.List = "";
        } else {
          y.List = d.list.name;
        }

        if (d.dispo) {
          y.Disposition = d.dispo;
        } else {
          y.Disposition = "";
        }

        if (d.callbackOn) {
          y.CallbackOn = this.$moment(d.callbackOn).format(
            "DD-MM-YYYY, h:mm a"
          );
        } else {
          y.CallbackOn = "";
        }

        if (d.email != "") {
          y.Email = d.email;
        } else {
          y.Email = "";
        }

        // Default Dialer
        if (d.type == "call" && typeof d.defaultDialer == "undefined") {
          y.defaultDialer = "Phone Dialer";
        } else {
          y.defaultDialer = d.defaultDialer;
        }

        if (d.type == "link") {
          y.defaultDialer = "";
        }

        if (d.list && d.list.teams) {
          y.Team = d.list.teams.name;
        } else {
          y.Team = "";
        }

        if (d.note) {
          y.Notes = d.note;
        } else {
          y.Notes = "";
        }

        if (d.duration) {
          y["duration (sec)"] = Number(d.duration / 1000).toFixed(2);
        }

        if (d.type == "call") {
          if (parseInt(d.Duration) > 0) {
            y.CallStatus = "Connected";
          } else {
            y.CallStatus = "Not Connected";
          }
        } else {
          y.CallStatus = "";
        }

        if (d.timeSpentOnCall) {
          y["TimeSpent (sec)"] = Number(d.timeSpentOnCall / 1000).toFixed(2);
        }

        var statusArray = [];

        if (d.emailSent) {
          statusArray.push("Email");
        }

        if (d.smsSent) {
          statusArray.push("SMS");
        }

        if (d.whatsappSent) {
          statusArray.push("Whatsapp");
        }
        if (d.linkSent) {
          statusArray.push("Link");
        }

        if (d.appSent) {
          statusArray.push("App");
        }

        if (d.called) {
          statusArray.push("Call");
        }

        if (typeof statusArray !== "undefined" && statusArray.length > 0) {
          y.Status = statusArray.join();
        } else {
          y.Status = "";
        }

        if (typeof d.template == "undefined" || d.template.title == "") {
          y.Template = "";
        } else {
          y.Template = d.template.title;
        }

        if (
          typeof d.truecallerCallRecording == "undefined" ||
          d.truecallerCallRecording.name == ""
        ) {
          y.CallRecordingPath = "";
        } else {
          if (typeof d.truecallerCallRecording.name != "undefined") {
            y.CallRecordingPath = `${this.$store.state.ENDPOINT}/storages/get/${d.truecallerCallRecording.name}?access_token=${this.$store.getters.user.token}`;
          } else {
            y.CallRecordingPath = "";
          }
        }

        if (
          typeof d.telecmi != "undefined" &&
          (d.defaultDialer == "Telecmi" ||
            d.defaultDialer == "Telecmi-Linphone")
        ) {
          if (typeof d.telecmi.filename != "undefined") {
            var url = `https://rest.telecmi.com/v2/play?appid=${self.telecmiIntegration.config.appid}&secret=${self.telecmiIntegration.config.secret}&file=${d.telecmi.filename}`;
            y.CallRecordingPath = url;
          } else {
            y.CallRecordingPath = "";
          }
        }

        if (typeof d["customFields"] != "undefined") {
          if (Array.isArray(d["customFields"])) {
            //CustomField Set For Array
            var customFields = d["customFields"];

            for (var each2 in customFields) {
              var c = customFields[each2];

              y["Custom : " + c.name] = c.value;
            }
          } else {
            //CustomField Set For Object
            for (var each in self.fieldList) {
              if (
                typeof d["customFields"] != "undefined" &&
                d["customFields"][self.fieldList[each].name]
              ) {
                var customVal = d["customFields"][self.fieldList[each].name];

                if (customVal) {
                  if (Array.isArray(customVal)) {
                    y[self.fieldList[each].name] = customVal.toString();
                  } else {
                    if (self.fieldList[each].type == "date") {
                      y[self.fieldList[each].name] = this.$moment(customVal)
                        .format()
                        .substr(0, 10);
                    } else {
                      y[self.fieldList[each].name] = customVal;
                    }
                  }
                } else {
                  y[self.fieldList[each].name] = "";
                }
              }
            }
          }
        }

        return y;
      });

      const newBook = XLSX.utils.book_new();
      const newSheet = XLSX.utils.json_to_sheet(exportData);
      XLSX.utils.book_append_sheet(newBook, newSheet, "Sheet1");
      XLSX.writeFile(newBook, `report_${new Date().getTime()}.xlsx`);
    },

    /** GET THE REQUIRED DATA */
    // getting all the logs depending on the selected user, list and daterange, limit and skip
    async fetchLogs(limit, skip) {
      try {
        this.loading = true;
        this.loadingText = "Loading... Please wait";

        this.snackbar = false;
        this.statistics = [];
        var users = [];
        var lists = [];
        var dispos = [];

        //Get lists
        lists = this.getListIds();

        //Get users
        users = this.getUserIds();

        if (this.dates.length < 1) {
          this.dates = [
            moment()
              .subtract(7, "days")
              .format("YYYY-MM-DD"),
            moment().format("YYYY-MM-DD"),
          ];
        }

        if (limit == undefined || limit == undefined) {
          limit = 5;
          skip = 0;
        }

        // dispo variable
        if (_.includes(this.selectedDispos, "All Dispos")) {
          // dispos = _.map(this.dispos, "title");
          dispos = [];
        } else {
          dispos = this.selectedDispos;
        }

        //final dispos
        dispos = _.without(dispos, "All Dispos");

        // Check Search String null
        if (this.search == undefined || this.search == null) {
          this.search = "";
        }
        var res = await this.$http.post(this.ENDPOINT + "/logs/byFilter", {
          search: this.search,
          field: this.searchField,
          range: this.dates,
          lists: lists,
          users: users,
          dispos: dispos,
          limit: limit,
          skip: skip,
          count: false,
        });

        var response = [];
        response = _.without(
          _.map(res.body.data, (r) => {
            if (r.calledOn != undefined) {
              r.formatedTime = moment(r.calledOn).format("YYYY-MM-DD hh:mm A");
              r.time = moment(r.calledOn).format("hh:mm A");
              r.date = moment(r.calledOn).format("YYYY-MM-DD");

              if (typeof r.truecallerCallRecording != "undefined") {
                r.recording = r.truecallerCallRecording;
                r.recording.isPlaying = false;
                r.recording.playbackTime = 0;
              } else {
                r.recording = { success: false };
              }

              if (
                typeof r.telecmi != "undefined" &&
                (r.defaultDialer == "Telecmi" ||
                  r.defaultDialer == "Telecmi-Linphone")
              ) {
                if (typeof r.telecmi["filename"] != "undefined") {
                  r.recording = {
                    success: true,
                    filename: r.telecmi.filename,
                    url:
                      "https://s3.ap-south-1.amazonaws.com/telecmi/" +
                      r.telecmi.filename,
                  };

                  r.recording.isPlaying = false;
                  r.recording.playbackTime = 0;
                } else {
                  r.recording = { success: false };
                }
              }

              if (r.type == "call" && typeof r.defaultDialer == "undefined") {
                r.defaultDialer = "Phone Dialer";
              }

              if (r.type == "link") {
                r.defaultDialer = "";
              }

              if (typeof r.duration != "undefined" && r.type == "call") {
                var inSecs = r.duration / 1000;

                if (inSecs > 60) {
                  r.Duration = `${Number(inSecs / 60).toFixed(1)} mins`;
                } else {
                  r.Duration = `${Number(inSecs).toFixed(0)}s`;
                }

                if (parseInt(r.duration) > 0) {
                  r.callStatus = "Connected";
                } else {
                  r.callStatus = "Not Connected";
                }
              }

              if (typeof r.timeSpentOnCall != "undefined" && r.type == "call") {
                var timeSpentOnCall = r.timeSpentOnCall / 1000;

                if (timeSpentOnCall > 60) {
                  r.TimeSpent = `${Number(timeSpentOnCall / 60).toFixed(
                    1
                  )} mins`;
                } else {
                  r.TimeSpent = `${Number(timeSpentOnCall).toFixed(0)}s`;
                }
                if (
                  typeof r.duration != "undefined" ||
                  r.duration === "" ||
                  r.duration === "0s"
                ) {
                  r.duration = r.TimeSpent;
                }
              }

              if (r.type != "call") {
                r.dispo = "";
                r.note = "";
              }

              // accounts

              var account = _.find(this.allUsers, (user) => {
                return r.accountsId == user.id;
              });

              if (account) {
                r.accounts = account;
              }

              //lists
              var list = _.find(this.lists, (list) => {
                return r.listId == list.id;
              });
              if (list) {
                r.list = list;
              }

              //templates
              var template = _.find(this.templates, (template) => {
                return r.templateId == template.id;
              });
              if (template) {
                r.template = template;
              }
            }

            try {
              var output = _.mapValues(r.customFields, (value, key) => {
                var fieldDef = _.find(this.fieldList, (f) => {
                  return f.name == key;
                });

                if (!fieldDef) return value;

                switch (fieldDef.type) {
                  case "date":
                    if (this.$moment(value).isValid())
                      value = this.$moment(value)
                        .format()
                        .substr(0, 10);
                    else value = "";
                    break;
                  case "text":
                    value = value.toString().trim();
                    break;
                  case "number":
                    value = Number(value);
                    break;
                  case "mcq":
                    if (!_.includes(fieldDef.mcqOptions, value)) value = "";
                    break;
                  // case "checkbox":
                  //   if (!_.includes(fieldDef.checkboxOptions, value)) value = "";
                  //   break;
                }
                return value;
              });
              r.customFields = output;
            } catch (e) {
              console.log(e);
            }

            return r;
          }),
          undefined
        );
        response = _.orderBy(response, "calledOn", "desc");

        this.totalConnectedCall = 0;
        this.totalNotConnectedCall = 0;

        var pieResponse = "";

        pieResponse = _.map(response, (d) => {
          if (d.type == "call") {
            if (parseInt(d.Duration) > 0) {
              this.totalConnectedCall++;
            } else {
              this.totalNotConnectedCall++;
            }
          }
        });

        //Pie Chart
        this.pieChartData = {
          labels: ["Connected Calls", "Not Connected Calls"],
          datasets: [
            {
              backgroundColor: ["#2ecc71", "#FF6384"],
              data: [this.totalConnectedCall, this.totalNotConnectedCall],
            },
          ],
        };

        console.log("response 13124214325423", response);

        this.loading = false;
        this.loadingText = "";
        return response;
      } catch (e) {
        console.log(e);
        this.loading = false;
        this.loadingText = "Something went wrong while fetching call logs";
      }
    },
    /** Total logs count [ used for pagination ] */
    async fetchCount() {
      // this.loading = true;
      // this.loadingText = "Loading... Please wait";

      var users = [];
      var lists = [];
      var dispos = [];

      //Get lists
      lists = this.getListIds();

      //Get lists
      users = this.getUserIds();

      if (this.dates.length < 1) {
        this.dates = [
          moment()
            .subtract(7, "days")
            .format("YYYY-MM-DD"),
          moment().format("YYYY-MM-DD"),
        ];
      }

      // dispo variable
      if (_.includes(this.selectedDispos, "All Dispos")) {
        // dispos = _.map(this.dispos, "title");
        dispos = [];
      } else {
        dispos = this.selectedDispos;
      }

      //final dispos
      dispos = _.without(dispos, "All Dispos");

      // Check Search string null
      if (this.search == undefined || this.search == null) {
        this.search = "";
      }

      this.$http
        .post(this.ENDPOINT + "/logs/byFilter", {
          search: this.search,
          field: this.searchField,
          range: this.dates,
          lists: lists,
          users: users,
          dispos: dispos,
          count: true,
        })
        .then((response) => {
          this.totalRecords = response.body;
          this.pageCount = Math.ceil(this.totalRecords / this.records);
        })
        .catch((e) => {});
    },
    async fetchTemplates() {
      var self = this;

      try {
        var res = await self.$http.get(
          self.$store.state.ENDPOINT +
            "/templates?filter[where][companyId]=" +
            self.$store.getters.user.companyId
        );

        self.templates = res.body;
      } catch (e) {
        console.log(e);
      }
    },
    async fetchLists() {
      try {
        this.lists = [];
        var filter = {
          where: {
            companyId: this.$store.state.user.companyId,
          },
          fields: ["id", "name", "teamsId"],
          include: ["teams", "accounts"],
        };

        if (this.$store.getters.user.role.toLowerCase() != "manager") {
          filter.where["teamsId"] = {
            inq: _.without(_.map(this.teams, "id"), "allTeams"),
          };
        }

        for (let i of this.fieldList) {
          this.allHeaders.push({
            text: i.name,
            align: "left",
            sortable: false,
            value: "customFields." + i.name,
          });
        }

        var res = await this.$http.get(
          `${this.ENDPOINT}/lists?filter=${encodeURIComponent(
            JSON.stringify(filter)
          )}`
        );
        this.lists = res.body;

        if (
          typeof this.user.permissions.canAccessList != "undefined" &&
          this.user.permissions.canAccessList === false
        ) {
          this.lists = _.filter(this.lists, (list) => {
            return _.find(list.accounts, (account) => {
              return account.id == this.user.id;
            });
          });
        }

        this.lists.unshift({
          id: "allLists",
          name: "All Lists",
        });

        if (this.selectedList.length < 1) {
          this.selectedList.push(this.lists[0].id);
        }
      } catch (e) {
        console.log(e);
      }
    },

    getTeamIds() {
      var ids = [];

      if (this.selectedTeam == "allTeams") {
        ids = _.map(this.teams, "id");
      } else {
        ids.push(this.selectedTeam);
      }

      ids = _.without(ids, "allTeams");
      return ids;
    },

    // Fetch Accounts
    async fetchAccounts() {
      this.allUsers = [];

      try {
        var teams = [];
        teams = await this.getTeamIds();

        var response = await this.$http.post(
          `${this.$store.state.ENDPOINT}/accounts/fetch`,
          {
            teams: teams,
          }
        );
        this.allUsers = response.body;
        if (this.user.role == "Manager" || this.user.role == "Teammanager") {
          this.allUsers.unshift({
            id: "allMembers",
            name: "All Members",
          });
        }
        if (this.selectedUser.length < 1) {
          this.selectedUser.push(this.allUsers[0].id);
        }
      } catch (e) {
        if (e.body.error && e.body.error.message)
          return this.$swal({ type: "error", text: e.body.error.message });
        else
          return this.$swal({
            type: "error",
            text: "Error while fetching accounts",
          });
      }
    },

    async fetchDispos() {
      try {
        this.dispos = [];

        var res = await this.$http.get(
          this.ENDPOINT +
            "/dispos?filter[where][companyId]=" +
            this.user.companyId
        );
        this.dispos = res.body;
        if (this.dispos.length > 0) {
          this.dispos.unshift({
            title: "All Dispos",
          });
        }

        if (this.selectedDispos.length < 1) {
          this.selectedDispos.push(this.dispos[0].title);
        }
      } catch (e) {
        console.log("dispo related Isususes", e);
      }
    },

    /** GRAPH REPORT */
    fetchGraphReports() {
      this.preloader = true;
      this.statistics = [];

      var users = [];
      var lists = [];
      var dispos = [];

      //Get lists
      lists = this.getListIds();

      //Get lists
      users = this.getUserIds();

      if (this.dates.length < 1) {
        this.dates = [
          moment()
            .subtract(7, "days")
            .format("YYYY-MM-DD"),
          moment().format("YYYY-MM-DD"),
        ];
      }

      if (this.dates.length < 1) {
        this.dates = [
          moment()
            .subtract(7, "days")
            .format("YYYY-MM-DD"),
          moment().format("YYYY-MM-DD"),
        ];
      }

      // dispo variable
      if (_.includes(this.selectedDispos, "All Dispos")) {
        // dispos = _.map(this.dispos, "title");
        dispos = [];
      } else {
        dispos = this.selectedDispos;
      }

      //final dispos
      dispos = _.without(dispos, "All Dispos");

      this.$http
        .post(this.ENDPOINT + "/logs/v2/graph", {
          range: this.dates,
          lists: lists,
          users: users,
          dispos: dispos,
        })
        .then((response) => {
          response = response.body;

          response.forEach((stat) => {
            this.statistics.push({
              _id: stat._id,
              duration: stat.duration,
              count: stat.count,
            });
            for (var y in stat.status) {
              let exists = _.find(this.graphData, (s) => {
                return s._id == stat.status[y]["_id"];
              });
              let index = _.indexOf(this.graphData, exists);
              if (index > -1) {
                this.graphData[index].count =
                  this.graphData[index].count + stat.status[y]["count"];
                continue;
              }
              this.graphData.push({
                _id: stat.status[y]["_id"],
                count: stat.status[y]["count"],
              });
            }
          });
          this.fillBarData();
          this.fillData();

          this.preloader = false;
        })
        .catch((e) => {
          this.preloader = false;
        });
    },

    fillBarData() {
      this.loadChart = false;
      var counter = new Object();
      this.totalCallCount = 0;
      this.totalCallDuration = 0;

      this.statistics = _.map(this.statistics, (d) => {
        var obj = {};
        obj.account = _.find(this.allUsers, (a) => {
          return a.id == d._id.accountsId;
        });
        obj.accountsId = d._id.accountsId;
        obj.count = d.count;

        if (d.duration != undefined) {
          d.duration = d.duration / 1000;
          obj.duration = Number(d.duration / 60);
          this.totalCallDuration += obj.duration;
        }

        if (obj.account != undefined) {
          counter[obj.account.name] = d.count;
        }
        this.totalCallCount += obj.count;

        return obj;
      });
      this.totalCallDuration = `${this.totalCallDuration.toFixed(1)} mins`;

      //Bar Chart
      this.barChartData = {
        datasets: [
          {
            backgroundColor: "#4cd964",
            data: [..._.map(counter)],
          },
        ],
        // These labels appear in the legend and in the tooltips when hovering different arcs
        labels: Object.keys(counter),
      };

      this.loadChart = true;
    },
    async fillData() {
      const self = this;
      self.datacollection = {};
      self.loadChart = false;
      self.labelShown = false;

      var colors = [];
      this.graphData = _.orderBy(this.graphData, ["count"], ["desc"]);
      if (this.graphData.length > 10) {
        var graphNew = this.graphData.slice(0, 10);
        var graphOther = {
          _id: "Others",
          count: 0,
        };
        for (let i = 10; i < this.graphData.length; i++) {
          graphOther.count += this.graphData[i].count;
        }
        this.graphData = graphNew.concat(graphOther);
      }
      _.each(this.graphData, (val) => {
        val = _.find(self.dispoList, (d) => {
          return d.title == val._id;
        });

        if (val == undefined) {
          colors.push("#d9d9d9");
        } else {
          colors.push(val.hex);
        }
      });
      this.labelShown = false;

      var labels = _.map(this.graphData, "_id");

      // //Chart
      self.datacollection = {
        datasets: [
          {
            backgroundColor: colors,
            data: _.map(this.graphData, "count"),
            borderWidth: 0,
          },
        ],
        // These labels appear in the legend and in the tooltips when hovering different arcs
        labels: labels,
      };

      self.labelShown = true;

      await self.$nextTick((err) => {
        self.loadChart = true;
        return;
      });
    },

    /** EXPORT (CSV) */
    async exportReport() {
      // Sleep function to pause execution for a set time (in milliseconds)
      function sleep(time) {
        return new Promise((resolve) => setTimeout(resolve, time));
      }

      // Initialize variables
      var exportData = [];
      this.loader1 = true; // Show the loading dialog
      this.exportProgress = 0; // Initialize the export progress
      const self = this;

      try {
        var dispos = [];
        // Get the lists and users to process
        var lists = this.getListIds();
        var users = this.getUserIds();

        // If no dates selected, set a default date range of the last 7 days
        if (this.dates.length < 1) {
          this.dates = [
            moment()
              .subtract(7, "days")
              .format("YYYY-MM-DD"),
            moment().format("YYYY-MM-DD"),
          ];
        }

        // Check and process selected dispositions
        if (_.includes(this.selectedDispos, "All Dispos")) {
          dispos = [];
        } else {
          dispos = this.selectedDispos;
        }
        dispos = _.without(dispos, "All Dispos");

        // Define the maximum number of contacts to process per query
        var maxCount = 2500;
        var data = [],
          res;

        // Calculate the number of loops required based on total records
        var totalBatches = Math.ceil(this.totalRecords / maxCount);
        this.totalBatches = totalBatches; // Store the total number of batches
        var limit = 0,
          skip = 0;
        var processedRecords = 0; // Track the number of processed records

        // Loop through all records in batches of maxCount
        for (
          let currentBatch = 1;
          currentBatch <= totalBatches;
          currentBatch++
        ) {
          this.currentBatch = currentBatch; // Store the current batch

          skip += limit;
          limit = maxCount;

          // Fetch records from the API
          res = await this.$http.post(`${this.ENDPOINT}/logs/byFilter`, {
            companyId: this.$store.getters.user.companyId,
            range: this.dates,
            lists: lists,
            users: users,
            dispos: dispos,
            limit: limit,
            skip: skip,
            count: false,
          });

          // Append the fetched data to the main data array
          data = data.concat(res.body.data);
          processedRecords += res.body.data.length; // Update the number of processed records

          // Update the export progress percentage
          this.exportProgress = Math.round(
            (processedRecords / this.totalRecords) * 100
          );

          await sleep(500); // Pause execution for 0.5 second between loops
        }

        // Set exportProgress to indicate merging stage
        this.exportProgress = 100; // Data fetching completed

        // Wait for a moment to show the completion of fetching
        await sleep(500);

        // Merging step, updating progress from 100 to 120 to indicate the process
        for (let i = 100; i <= 120; i++) {
          this.exportProgress = i; // Showing merge progress
          await sleep(100); // Adjust this timing if needed to show smoother progress
        }

        // Map the data to enrich it with additional information
        data = _.map(data, (r) => {
          // Find and append account information
          var account = _.find(this.allUsers, (user) => {
            return r.accountsId == user.id;
          });
          if (account) {
            r.accounts = account;
          }

          // Find and append list information
          var list = _.find(this.lists, (list) => {
            return r.listId == list.id;
          });
          if (list) {
            r.list = list;
          }

          // Find and append template information
          var template = _.find(this.templates, (template) => {
            return r.templateId == template.id;
          });
          if (template) {
            r.template = template;
          }

          return r;
        });

        exportData = _.orderBy(data, "time", "desc");
      } catch (e) {
        console.log(e);
        this.loader1 = false;
        return this.$swal({
          type: "error",
          text: "Connection error. Try again later.",
        });
      }

      // Check if there is any data to export
      if (exportData.length < 1) {
        this.loader1 = false;
        this.$swal({ type: "warning", text: "No records to export" });
        return;
      }

      // Transform the data for exporting
      exportData = _.map(exportData, (d) => {
        var y = {
          Phone: d.phone.toString(),
        };

        y.Name = d.name || "";
        y.Company = d.companyName || "";
        y.Date = d.calledOn
          ? this.$moment(d.calledOn).format("YYYY-MM-DD")
          : "";
        y.Time = d.calledOn ? this.$moment(d.calledOn).format("hh:mm a") : "";
        y.Leadscore = d.leadScore || "";
        y.Remarks = d.remarks || "";
        y.Extra = d.extra || "";
        y.CalledBy = d.accounts && d.accounts.name ? d.accounts.name : "";
        y.Direction = d.direction || "outgoing";
        y.List = d.list && d.list.name ? d.list.name : "";
        y.Disposition = d.dispo || "";
        y.CallbackOn = d.callbackOn
          ? this.$moment(d.callbackOn).format("DD-MM-YYYY, h:mm a")
          : "";
        y.Email = d.email || "";
        y.defaultDialer =
          d.type === "call" && d.defaultDialer === undefined
            ? "Phone Dialer"
            : d.defaultDialer || "";
        y.Team = d.list && d.list.teams ? d.list.teams.name : "";
        y.Notes = d.note || "";
        y["duration (sec)"] = d.duration
          ? Number(d.duration / 1000).toFixed(2)
          : "";
        y.CallStatus =
          d.type === "call"
            ? parseInt(d.duration) > 0
              ? "Connected"
              : "Not Connected"
            : "";
        y["TimeSpent (sec)"] = d.timeSpentOnCall
          ? Number(d.timeSpentOnCall / 1000).toFixed(2)
          : "";

        // Status array
        var statusArray = [];
        if (d.emailSent) statusArray.push("Email");
        if (d.smsSent) statusArray.push("SMS");
        if (d.whatsappSent) statusArray.push("Whatsapp");
        if (d.linkSent) statusArray.push("Link");
        if (d.appSent) statusArray.push("App");
        if (d.called) statusArray.push("Call");

        y.Status = statusArray.length > 0 ? statusArray.join() : "";

        // Template data
        y.Template = d.template && d.template.title ? d.template.title : "";

        // Call Recording Path
        if (
          d.truecallerCallRecording &&
          d.truecallerCallRecording.name &&
          typeof d.truecallerCallRecording.name !== "undefined"
        ) {
          y.CallRecordingPath = `${this.$store.state.ENDPOINT}/storages/get/${d.truecallerCallRecording.name}?access_token=${this.$store.getters.user.token}`;
        } else {
          y.CallRecordingPath = "";
        }

        if (
          d.telecmi &&
          (d.defaultDialer == "Telecmi" ||
            d.defaultDialer == "Telecmi-Linphone")
        ) {
          if (typeof d.telecmi.filename != "undefined") {
            var url = `https://rest.telecmi.com/v2/play?appid=${self.telecmiIntegration.config.appid}&secret=${self.telecmiIntegration.config.secret}&file=${d.telecmi.filename}`;
            y.CallRecordingPath = url;
          } else {
            y.CallRecordingPath = "";
          }
        }

        // Handle custom fields
        if (d["customFields"]) {
          if (Array.isArray(d["customFields"])) {
            var customFields = d["customFields"];
            for (var each2 in customFields) {
              var c = customFields[each2];
              y["Custom : " + c.name] = c.value;
            }
          } else {
            for (var each in self.fieldList) {
              if (d["customFields"][self.fieldList[each].name]) {
                var customVal = d["customFields"][self.fieldList[each].name];
                if (customVal) {
                  y[self.fieldList[each].name] = Array.isArray(customVal)
                    ? customVal.toString()
                    : self.fieldList[each].type == "date"
                    ? this.$moment(customVal)
                        .format()
                        .substr(0, 10)
                    : customVal;
                } else {
                  y[self.fieldList[each].name] = "";
                }
              }
            }
          }
        }

        return y;
      });

      // Create the Excel file
      const newBook = XLSX.utils.book_new();
      const newSheet = XLSX.utils.json_to_sheet(exportData);
      XLSX.utils.book_append_sheet(newBook, newSheet, "Sheet1");

      // Indicate that merging is done and downloading starts
      this.exportProgress = 130; // Update to show download preparation
      XLSX.writeFile(newBook, `report_${new Date().getTime()}.xlsx`);

      this.loader1 = false; // Hide the loading dialog once done
      fb.log("report_export");
    },
  },
};
</script>

<style scoped></style>
